<template>
  <window-content>
    <div class="wrapper-sm">
      <e-row mr>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="ID do Leilão"
          >
            <div style="display: flex; justify-content: space-between">
            <div class="col-grow-1">
              <erp-input v-model="filters.id" />
            </div>
              <a class="m-l-xs" @click="SearchLeilao"><i class="erp-icon search min"></i> </a>
            </div>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field
              view="tl"
              label="Data Inicial"
          >
            <erp-input v-model="filters.data1" v-mask="'##/##/####'" />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field
              view="tl"
              label="Data Final"
          >
            <erp-input v-model="filters.data2" v-mask="'##/##/####'" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Código"
          >
            <erp-input v-model="filters.codigo" />
          </erp-s-field>
        </e-col>

        <e-col style="min-width: 98%">
          <comitente-select :columns="[
                      {label: 'Nome', value: 'label'},
                      {label: 'Tipo', value: 'tipoName'}
                    ]" v-model="filters.comitente" />
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <u-checkbox v-model="filters.mostrarEstatisticas">Estatísticas</u-checkbox>
          <u-checkbox v-model="filters.mostrarVendidos" class="m-l-sm">Vendidos</u-checkbox>
          <u-checkbox v-model="filters.mostrarNaoVendidos" class="m-l-sm">Não Vendidos</u-checkbox>
          <u-checkbox v-model="filters.mostrarRetirados" class="m-l-sm">Retirados</u-checkbox>
        </e-col>
      </e-row>
    </div>
    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)"/>
        <u-btn @click="gerar(formato)" :loading="loading" label="Gerar" icon="print" icon-type="fa" icon-style="light" flat no-caps/>
      </div>
    </window-footer>
  </window-content>
</template>

<script>
import {
  WindowContent,
  WindowFooter,
  ErpSField,
  ErpInput,
  /*ErpSelect*/
} from 'uloc-vue-plugin-erp'
import {UCheckbox} from 'uloc-vue'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import mixinPrint from "@/components/relatorios/components/mixinPrint"
import {relatorio} from "@/domain/relatorios/services"
import createPrint from "@/components/relatorios/components/customPrint/createPrint"
import ComitenteSelect from "@/components/comitente/helpers/input/ComitenteSelect"
import {datePtToEn} from "@/utils/date"
import SearchLeilao from "components/leiloes/components/window/search/SearchLeilaoCopiaLotes"

let filters = {
  id: null,
  codigo: null,
  data1: null,
  data2: null,
  comitentes: [],
  comitente: null,
  mostrarVendidos: true,
  mostrarNaoVendidos: true,
  mostrarRetirados: true,
  mostrarEstatisticas: true
}

export default {
  name: 'PrestacaoContasLeilaoWindow',
  mixins: [mixinPrint],
  data() {
    return {
      formato: 'html',
      filters: JSON.parse(JSON.stringify(filters))
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    createPrint: createPrint,
    actionPrint (action) {
      console.log('Print Action ', action)
      this.gerar(action)
    },
    gerar(formato = 'html') {
      let filters = JSON.parse(JSON.stringify(this.filters))

      if (!filters.id && !filters.codigo && (!filters.data1 || !filters.data2)) {
        this.$uloc.dialog({
          className: 'erp-confirm c-error',
          title: 'Preenchimento inválido',
          color: 'info',
          message:'Preencha o ID, código ou data do leilão'
        })
        return
      }

      if (filters.data1) {
        filters.data1 = datePtToEn(filters.data1)
      }
      if (filters.data2) {
        filters.data2 = datePtToEn(filters.data2)
      }

      this.loading = true
      relatorio('prestacaoContasLeilao', filters, formato)
          .then(response => {
            this.loading = false
            formato === 'html' && this.createPrint(response.data, () => import('@/components/relatorios/components/customPrint/DefaultPrint'), 'gerencial', 'Prestação de Contas de Leilão', 'portrait')
          })
          .catch(error => {
            this.loading = false
            if (formato === 'html') {
              this.alertApiError(error)
            } else{
              error.data.text().then(text => {
                this.alertApiError({data: JSON.parse(text)})
              })
            }
          })
    },
    SearchLeilao,
    __emit(id) {
      this.filters.id = id
    }
  },
  components: {
    ECol,
    ERow,
    WindowFooter,
    WindowContent,
    ErpSField,
    ErpInput,
    ComitenteSelect,
    UCheckbox
  }
}
</script>
